<template>
    <div id="pharmacy-list">

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="Liste des officines" @refresh="loadData" :add="true"
                    @plusClicked="plusClicked" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import DropDownCellRenderer from './DropDownCellRenderer.vue';

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Code",
                        field: "id",
                    },
                    {
                        headerName: "Username",
                        field: "userAccount",
                    },
                    {
                        headerName: "Nom",
                        field: "firstName",
                    },
                    {
                        headerName: "Prénom",
                        field: "lastName",
                    },
                    {
                        headerName: "Gouvernorat",
                        field: "governorate",
                    },
                    {
                        headerName: "Délégation",
                        field: "delegation",
                    },
                    {
                        headerName: "Adresse",
                        field: "address",
                    },
                    {
                        headerName: "Code Postal",
                        field: "postalCode",
                    },
                    {
                        headerName: "Tél. Fixe",
                        field: "landlinePhone",
                    },
                    {
                        headerName: "Tél. Portable",
                        field: "mobilePhone",
                    },
                    {
                        headerName: "E-mail",
                        field: "email",
                    },
                    {
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'dropDownCellRenderer',
                        cellRendererParams: { onButtonClick: this.onPharmacyClick },
                        sortable: false,
                        resizable: false,
                        filter: false,
                        // width: 50
                    },
                ],
                rows: []
            },
        }
    },
    created() {
        this.$options.components.dropDownCellRenderer = DropDownCellRenderer;
        this.loadData();
    },
    methods: {
        async onPharmacyClick(rowData, action) {
            if (action === "password") {
                this.$swal({
                    title: 'Êtes vous sûr de changer le mot de passe de ' + rowData.firstName + " " + rowData.lastName + '?',
                    text: "Vous ne pourrez pas revenir en arrière !",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Oui, modifiez-le !',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.value) {
                        this.showLoading = true;
                        let response = await this.$http.post("account/modifyPassword/" + rowData.userAccount).catch(() => {
                            this.showLoading = false;
                        });
                        this.$swal({
                            title: 'Mot de passe mis à jour avec succès',
                            text: response.data,
                            icon: 'success',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            showClass: {
                                popup: 'animate__animated animate__flipInX',
                            },
                            buttonsStyling: false,
                        });
                        this.showLoading = false;
                    }
                })
            }
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("pharmacy").catch(() => {
                this.showLoading = false;
            });
            this.agGrid.rows = response.data;
            this.showLoading = false;
        },
        plusClicked() {
            this.$router.push({ name: 'supervisor-pharmacy-new' });
        }
    }
}
</script>

<style lang="scss"></style>
