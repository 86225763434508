<template>
    <div id="dropdown-component">
        <div>
            <b-dropdown id="dropdown-grouped" size="sm" variant="outline-primary"
                class="dropdown-icon-wrapper custom-dropdown btn-icon">
                <template #button-content>
                    <feather-icon icon="SettingsIcon" size="12" class="align-middle spinner" />
                </template>
                <b-dropdown-item variant="secondary" @click="params.onButtonClick(params.data, 'password')"> <feather-icon
                        icon="LockIcon" size="18" /> Modifier mot de passe
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>
  
<script>

export default {
};
</script>
  
<style scoped>
.custom-dropdown {
    position: static !important;
    display: block !important;
    z-index: 9999;
}
</style>
  